import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Page1() {
    return (
        <Container>
          <Row>
            <Col className="text-center mt-5">1 of 1</Col>
          </Row>
        </Container>
    )
}
